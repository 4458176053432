@charset "UTF-8";
@import "../../../setting.scss";
@import "../../../mixin.scss";
@import "../../../form.scss";

@mixin subTableHeader() {
    background-color: $sub_table_thead_background_color;
    border: none;
    text-align: center;
    padding: 6px 0;
}

table {
    th,
    td {
        &.valign_t {
            vertical-align: top !important;
            padding-top: 15px !important;
        }
    }
    tr.alert {
        td {
            color: $error_text_color;
            font-weight: normal;
        }
    }
    tbody {
        tr {
            height: 54px;
        }
    }
}

.table_wrapper {
    @include border-default();
    table {
        tr:last-child td {
            border-bottom: none !important;
        }
    }
}
.table_responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    table {
        width: 100%;
    }
}

.table_sticky{
    /*display: block;*/
    border-collapse: separate !important;
    border-spacing:0;
    border: none !important;
    thead th {
        /* 縦スクロール時に固定する */
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        /* tbody内のセルより手前に表示する */
        z-index: 1;
    }
}

table {
    border-collapse: collapse !important;
    margin: 0 0;
    white-space: nowrap;
    @include border-default();
    &.table,
    &.sub_table {
        color: $font_color;
        font-size: 12px;
        width: 100%;
        margin-bottom: 0;
        tbody {
            td {
                @include border-default();
                border-top: none;
                //line-height: 35px;
                vertical-align: middle;
                padding: 5px 1rem;
            }            
        }
    }
    &.table {
        thead {
            th {
                background-color: $table_thead_background_color;
                @include border-default();
                color: $table_thead_color;
                line-height: 30px;
                padding: 0px 7px;
                white-space: nowrap;
                vertical-align: middle;
                text-align: center;
                &.selected {
                    background-color: rgb(185, 218, 224);
                    // background-color: $table_th_selected_color;
                }
                //border-bottom: 2px solid $table_thead_line_color;
                i {
                    margin-left: 3px;
                    &.down.on::after {
                        cursor: pointer;
                        margin-bottom: 2px;
                        @include triangle(5px, 5px, 0, 5px, $top_color:$site_color);
                    }
                    &.up.on::after {
                        cursor: pointer;
                        margin-bottom: 2px;
                        @include triangle(0, 5px, 5px, 5px, $bottom_color:$site_color);
                    }
                }
                &.no_sort {
                    i {
                        display: none;
                    }
                }
            }
        }
    }
    &.sub_table {
        thead {
            th {
                @include subTableHeader();
            }
        }
    }

}
.table_striped tbody tr:nth-of-type(even) {
    background-color: $table_td_even_color;
}
.table_striped tbody tr:nth-of-type(odd) {
    background-color: $table_td_odd_color;
}

/*table for form*/
table.table_edit {
    width: 100%;
    border: none;
    //border-collapse: separate;
    //border-spacing: 5px 10px;
    th,td {
    }
    th {
        /*background-color: $table_color;*/
        border: none;
        padding: 10px 10px 0 0;
        clear: both;
        font-weight: normal;
        vertical-align: middle;
        //line-height: 45px;
        .required {
            background-color: $table_color;
            line-height: 18px;
            padding: 0 4px;
            color: white;
            font-size: 11px;
            margin-right: 4px;
            margin-top: 1px;
            float: right;
        }
    }
    td {
        padding: 10px 0 0 0;
        border: none;
        &.btn_fix_box {
            position: relative;
            button {
                position: absolute;
                bottom: 6px;
                right: 8px;
            }
        }
    }
}

table.table_selectable {
    tbody {
        > tr {
            &:hover {
                td {
                    background-color: $selectecd_color !important;
                }
            }
            &.selected {
                td {
                    background-color: $selectecd_color;
                }
            }
            &.invalid {
                td {
                    background-color: $invalid_color;
                }
            }
            // td {
            //     cursor: pointer;
            // }
            &.grayed_out {
                background-color: #CCCCCC;
                color: gray;
        }

        }
    }
}

table.table_filelist {
    @include radius(4px);
    width: 100%;
    font-size: 12px;
    th {
        @include subTableHeader();
    }
    td {
      @include border-default();
      border-top: none;
      border-left: none;
      border-right: none;
      text-align: center;
      padding: 0;
      line-height: 25px;
    }
    tr:last-child {
        td {
            border-bottom: none;
        }
    }
}


/*
.card {
    border: 3px solid $table_color;
    border-radius: 20px;
    margin-bottom: 40px;
    .inner {
        padding: 20px 100px 30px;
    }
    table {
        border: 1px solid $table_color;
        margin-bottom: 0;
        border-collapse: collapse;
        thead {
            th {
                background-color: white;
                color: $form_text_color;
                padding: 4px 0;
            }
        }
        tbody {
            th,
            td {
                color: $form_text_color;
            }
            th {
                background-color: inherit;
                text-align: left;
            }
            td {
                text-align: center;
            }
        }
    }
}
*/
