@charset "UTF-8";
@import "../../setting.scss";
@import "../../mixin.scss";

.dialog_wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    z-index: 999;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}
.dialog {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #FFF;
    min-width: 560px;
    max-width: 80%;
    max-height: 80%;
    @include radius(4px) ;
     header {
        margin: 40px 30px 0;
        border-bottom: 1px solid $border_edit_wrap_color;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        /*background-color: $site_color;*/
        /*color: #FFF;*/
        line-height: 40px;
        padding: 15px 0px;
        font-size: 24px;
        text-align: center;
        font-weight: bold;
        @include radiusDetails(4px,4px,0,0) ;
    }
    .dialog_body {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        font-size: 14px;
        height: 100%;
        padding: 0 30px 50px;
        /*> div {
            display: flex;
            flex-direction: column;
            flex: 1;
            height: 100%;
            overflow: hidden;
        }*/
        .inner {
            flex: 1;
            overflow-y: auto;
            /*padding: 15px 30px;*/
            position: relative;
            .text_box {
                padding: 50px 0;
                text-align: center;
                font-size: 18px;
/*                + .input_wrap {
                    margin-top: 30px;
                    input {
                        margin-bottom: 0;
                    }
                }
                + input {
                    margin-top: 10px;
                }
*/
            }
/*
            &.small_dialog {
                width: 400px;
                padding: 35px 30px 0;
                text-align: center;
                input {
                    width: 300px;
                }
                + footer {
                    padding: 35px 0;
                }
            }
*/
/*            h3 {
                padding-left: 10px;
                border-left: 8px solid #F4A464;
                border-bottom: 1px solid #F4A464;
                font-weight: normal;
                font-size: 16px;
                line-height: 30px;
                margin-bottom: 30px;
            }
            .table_status {
                position: absolute;
                top: 15px;
                right: 30px;
                border: none;
                th {
                    font-weight: normal;
                }
                td {
                    text-align: center;
                }
            }
*/
        }
        footer {
            text-align: center;
        }
    }
}

.for_send_mail {
    display: flex;
    padding: 110px 20px 80px;
    align-items: center;
    img {
        margin-right: 40px;
    }
    .text_box {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        font-size: 18px;
        font-weight: bold;
        padding: 0 !important;
        > div {
            text-align: left;
        }
    }
}

//直接遷移用
.dialog_wrap {
    &.direct_access {
        background-color: #FFF;
        overflow-y: auto;
        .dialog {
            display: block;
            padding: 50px 0;
            max-height: inherit;
            header {
                margin: 40px 0 0;
            }
            .dialog_body {
                padding: 60px 0 0;
            }
        }
        &.add {
            .dialog {
                header {
                    border-bottom: none;
                }
                .dialog_body {
                    padding: 20px 0 0;
                    .item_cnt {
                        min-width: 550px;
                    }
                }
            }
        }
        &.check {
            .dialog {
                header {
                    width: 840px;
                }
                .dialog_body {
                    padding: 60px 90px 0;
                }
            }
        }
        &.result {
            .dialog {
                .dialog_body {
                    padding: 0;
                    footer {
                        width: 300px;
                        margin: 0 auto;
                        button {
                            width: 300px;
                            img {
                                vertical-align: top;
                                margin-right: 8px;
                            }
                            span {
                                display: inline-block;
                                line-height: 23px;
                            }
                            + button {
                                margin: 15px 0 0;
                            }
                        }
                    }
                }
            }
        }
        &.password {//パスワード再発行
            .dialog {
                max-width: 840px;
                width: 80%;
                header {
                    .text_box {
                        font-size: 16px;
                        font-weight: normal;
                    }
                }
                .dialog_body {
                    width: 420px;
                    margin: 0 auto;
                    .edit_wrap {
                        .item_wrap {
                            .item_head {
                                font-size: 14px;
                                font-weight: bold;
                                margin-bottom: 5px;
                            }
                            .item_cnt {
                                padding: 0;
                                input {
                                    width: 100%;
                                    line-height: 50px;
                                }
                            }
                            + .item_wrap {
                                margin-top: 35px;
                            }
                        }
                    }
                    .text_box {//送信完了画面
                        font-size: 18px;
                        font-weight: bold;
                        text-align: center;
                        padding-top: 20px;
                        padding-bottom: 50px;
                        line-height: 35px;
                    }
                }
                footer {
                    margin-top: 60px;
                }
            }
        }
    }
}