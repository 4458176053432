.ledger_container {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1.5rem;
  width: calc(100% - 200px);
  overflow-y: hidden;
  height: 100%;
  .header_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    &.center {
      justify-content: center;
    }
    .title_wrap {
      display: flex;
      align-items: flex-end;
      gap: 1rem;
      .title {
        font-size: 24px;
      }
    }
  }
  .ledger_body_container {
    display: flex;
    height: 100%;
    width: 70%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    .button_area {
      display: flex;
      justify-content: center;
      margin-bottom: 3rem;
      gap: 1rem;
    }
    .ledger_body_main_cnt {
      width: 100%;
      height: 100%;
      overflow-y: hidden;
      display: flex;
      flex-direction: column;
    }
    .file_name_container {
      margin-top: 0.25rem;
      margin-bottom: 3rem;
    }
    .model_update_date {
      margin-top: 4rem;
      font-weight: bold;
      text-align: center;
    }
    .table_container {
      // margin-bottom: 30px;
      flex: 1 1;
      overflow: hidden;
      max-height: 100%;
      .table_responsive {
        max-height: 100%;
      }
    }
  
  }
}