@charset "utf-8";

$site_color: #e35d67;
$site_sub_color: #D5E9F0;
$accent_color: #E35D67;

$selectecd_color: #FFF9D9; //選択
$invalid_color: #CCCCCC; //無効、削除

/*font color*/
$font_color: /*#707070*/#4F4F4F;
$header_font_color: #FFF;
$footer_font_color: #FFF;
$error_text_color: #E35D67;
$placeholder_color: rgba(112,112,112,0.6);

/*header */
$header_background_color: #6B6B6B;

/*border*/
$border_default_color :#E2E2E3;
$border_edit_wrap_color :#707070;

/*hr*/
$hr_color:rgba(0,0,0,0.03);

/*table*/
$table_color:#D1DDED;
$table_thead_background_color:#eee;
// $table_thead_background_color:rgba(0,0,0,0.078);
$table_thead_color:rgba(0,0,0,0.87);
$table_thead_line_color :#3554844D;
$sub_table_thead_background_color:#D6DDE6;

/*$table_thead_color:#FFFFFF;*/
$table_td_even_color:/*rgba(0, 0, 0, 0.03)*/#F7F7F7;
$table_td_odd_color:#FFF;
$table_th_selected_color:rgba(23,163,180,0.4);

/*table for edit*/
$item_head_background_color: rgba(0,0,0,0.031);
$required_color:#F90000;

/*button*/
$btn_primary_color: $site_color;
$btn_secondary_color: #139284;
$btn_tertiary_color: #FFFFFF;

/*radio*/
$radio_color: #4D4D4D;

/*scrollbar*/
$scrollbar_bg_color: #E0E0E0;
$scrollbar_color: $site_color;

/*dialog*/
//$exception_dialog_inner_color: rgba(255,255,255,0.811);/*#FFFFFFCF*/
$exception_dialog_inner_color: #DBDBDB;

/*for ISP*/
$side_bar_background_color: #303641;
$portal_background_color:#F3F2F7;

/*type*//*for ISP*/
$type_color_0:#5997DF;
$type_color_1:#A1AF2F;
$type_color_2:#DF8759;
$type_color_3:#888888;
$type_color_4:#5997DF;

/*
$main_header_color: #606060;
$header_footer_background: #F2F2F2;
$header_text_color: #707070;
$header_border_color: #1558a7;
// $footer_text_color: #606060;
$footer_text_color: #000000;
$auth_footer_background_color: rgba(255,255,255,0.5);
$auth_footer_text_color: #000000;
*/
/*
$margin_bottom_default: 10px;

$list_background_color :#F2F2F2;
$list_background_disabled_color :#E4E4E4;
$list_id_background_color :#7F7F7F;

$drag_area_background_color : #EDEDED;
*/

/*form*/
$form_color: #BFBFBF;
$form_border_color: #DDDDDD;
$form_background_color: #FAFAFA;
$form_file_background_color: #ecf0f6;
$form_text_color: #606060;
$form_border_color:#707070;
$form_checkbox_border_color:#707070;
$form_checkbox_background_color:#FFF;
$form_checkbox_check_color:#707070;
$error_text_color: #E35D67;

$disabled_background_color: #EEEEEE;
$disabled_color: #CFCFCF;

$sidemenu_current_background_color: rgba(255, 255, 255, 0.75);

$icon_off_color:#DBDCDE;
$active_border_color:#9AB4D6;
$thumbnail_border_color: #707070;
$link_color: #707070;
$common_title_border_color: #BFBFBF;

/*modal*/
$modal_overlay_color: rgba(0,0,0,.33);
$modal_close_color: #606060;

/*pager*/
$pager_text_color: rgba(33,133,208,0.87);