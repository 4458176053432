@use '../../../setting' as st;
@use '../../../mixin' as mx;

// 仕様なしにつき暫定適用
.tooltip {
  $center_offset: -50%;
  $top_offset: -100%;
  $bottom_offset: 0px;
  $blowing_size: 6px;

  font-size: 100%;
  font-style: inherit;
  font-weight: normal;
  position: absolute;
  max-width: 30em;
  padding: 5px;
  background-color: #fff;
  color: #383838;
  border-radius: 5px;
  line-height: 1.5;
  z-index: 1;
  text-align: left;
  @include mx.box_shadow($x: 0, $y: 0, $blur: 3px, $color: rgb(0 0 0 / 25%));
  opacity: 0;
  animation: 0.2s linear 0.2s fade_in forwards;
  white-space: nowrap;

  &.error {
    color: st.$error_text_color;
    font-weight: bold;
    border: 2px solid st.$error_text_color !important;
  }

  &.pos_center_center {
    .blowing {
      display: none;
    }
  }
  &__message_row {
    word-break: break-all;
  }

  .blowing {
    font-size: $blowing_size;
    width: 2em;
    height: 2em;
    color: currentColor;
    @include mx.flex_all_center();
    position: absolute;

    &:before,
    &:after {
      display: block;
      content: "";
      border-bottom: 2em solid currentColor;
      border-left: calc(1em / 5 * 5) solid transparent;
      border-right: calc(1em / 5 * 5) solid transparent;
      position: absolute;
    }

    &:before {
      font-size: 100%;
    }
    &::after {
      font-size: calc(100% - 1px);
      border-bottom-color: #fff;
      z-index: 1;
      transform: translate(1px, 4px);
    }

    &.down {
      top: calc(100% + 1px);
    }
    &.up {
      bottom: calc(100% + 1px);
    }
    &.right {
      right: 10px;
      &.center {
        left: -1px;
        top: 50%;
        transform: translate(-100%, -50%) rotateZ(-90deg);
      }
    }
    &.left {
      left: 10px;
      &.center {
        left: initial;
        right: -1px;
        top: 50%;
        transform: translate(100%, -50%) rotateZ(90deg);
      }
    }
    &.center {
      left: 50%;
      transform: translateX(-50%);
      &.down {
        // transform: translateX(-50%) rotateZ(180deg);
      }
    }
  }
}

@keyframes fade_in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
