@import "../../../setting.scss";

.loader {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0%  { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}