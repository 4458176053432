@use '../../../mixin' as mx;
@use '../../../setting' as st;

.csv_upload_dialog {
  .dialog {
    width: 62.5vw;
    max-width: 800px;
    height: 340px;
    &_body {
      padding: 1em !important;
      .inner {
        $border_unit_size: 1rem;
        $border_width: 2px;
        $fill_size: 0.5rem;
        $fill_color: #e2e2e3;
        background-image: linear-gradient(
            to right,
            $fill_color,
            $fill_color $fill_size,
            transparent $fill_size,
            transparent $border_unit_size
          ),
          linear-gradient(
            to right,
            $fill_color,
            $fill_color $fill_size,
            transparent $fill_size,
            transparent $border_unit_size
          ),
          linear-gradient(
            to bottom,
            $fill_color,
            $fill_color $fill_size,
            transparent $fill_size,
            transparent $border_unit_size
          ),
          linear-gradient(
            to bottom,
            $fill_color,
            $fill_color $fill_size,
            transparent $fill_size,
            transparent $border_unit_size
          );
        background-size: $border_unit_size $border_width,
          $border_unit_size $border_width, $border_width $border_unit_size,
          $border_width $border_unit_size;
        background-position: left top, left bottom, left top, right top;
        background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
      }
    }
  }

  &__body {
    font-size: 1.25rem;
    @include mx.flex_box;
    @include mx.flex_direction_column;
    height: 100%;
    @include mx.flex_all_center;

    &.draggable {
      background-color: rgba($color: st.$site_color, $alpha: 0.15);
    }

    > div {
      &:last-of-type {
        @include mx.flex_box;
        @include mx.flex_direction_column;
        width: 10.5em;
        margin-top: 2em;
        gap: 1em;

        button {
          font-size: 100%;
          padding: 0.5em 1.5em;
          height: max-content;
          width: 100%;
          & + button {
            margin-left: 0 !important;
          }
        }
      }
    }
  }
  /* .csv_upload_dialog */
}
