@use '../../../mixin' as mx;
@use '../../../setting' as st;

.input_date {
  display: -ms-grid !important;
  display: grid !important;
  position: relative;
  min-width: 11.5em;
  max-width: 11.5em;
  background-color: #fff;
  &.disabled {
    background-color: hsl(0, 0%, 95%);
  }

  input {
    width: 100%;
    padding: 0 0 0 10px !important;
    z-index: 1; // カレンダーボタン押せるように(shadow-root取得できなかったため)
    background-color: transparent;
    user-select: none;
    &::-webkit-inner-spin-button {
      // -webkit-appearance: none;
    }
    &::-webkit-clear-button {
      // -webkit-appearance: none;
    }
    &::-webkit-datetime-edit {
      // visibility: hidden;
    }
    &::-webkit-calendar-picker-indicator {
      height: 100%;
      padding: 0 10px;
      opacity: 0;
      cursor: pointer;
    }
  }
  &__actions {
    @include mx.flex_box;
    @include mx.flex_align_center;
    position: absolute;
    right: 6px;
    width: max-content;
    height: 100%;
    gap: 0.25em;

    button {
      font-size: 100%;
      font-family: inherit;    
      padding: 0;
      z-index: 1; // inputに覆われないように
      @include mx.flex_box;
      @include mx.flex_align_center;
      img {
        width: 1.25em;
      }
    }
    // カレンダー画像
    > img {
      width: 1.56em;
    }
  }
}
