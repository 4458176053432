@import "../../../setting.scss";

.user_list_container {
  padding: 2rem 1.5rem;
  width: calc(100% - 200px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  .header_wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    &.center {
      justify-content: center;
    }
    .title_wrap {
      display: flex;
      align-items: flex-end;
      gap: 1rem;
      .title {
        font-size: 24px;
      }
    }
    .list_count {
      font-size: 14px;
      margin-bottom: 5px;
    }
    .menu_wrap {
      display: flex;
      align-items: center;
      height: 48px;
      >button {
        width: 142px;
      }
    }
  }
  .table_wrap {
    width: 90%;
    margin-bottom: 30px;
    flex: 1 1;
    overflow: hidden;
    max-height: 100%;
    .table_responsive {
      max-height: 100%;
    }
  }
}
