@import "../../../setting.scss";

.drop_zone_container {
  width: 100%;
  border: 1px dashed;
  background: #00000008;
  // max-width: 620px;
  // min-width: max-content;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  &.draggable {
    background-color: rgba($color: $site_color, $alpha: 0.15);
  }
}