.labeled_form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  gap: 0 10px;
  color: #4f4f4f;

  &__label {
    font-weight: bold;
    white-space: nowrap;
  }

  &.top_label_form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  
    .labeled_form__label {
      font-size: 0.75em;
      line-height: 1.5;
    }
  }
}
