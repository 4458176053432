@import "../../../setting.scss";

.user_edit_dialog {
  padding: 1rem;
  .item_wrap {
    flex-direction: column;
    .divider {
      border-bottom-color: $item_head_background_color;
      border-bottom: 1px solid;
      width: 100%;
      height: 1px;
      margin-top: 2px;
      margin-bottom: 7px;
    }  
    .edit_wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1rem;
        .item_head {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 5px;
          width: 250px;
        }
        .item_cnt {
          &.flex {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }
          .name_wrapper {
            &.flex {
              display: flex;
              align-items: center;
              gap: 2rem;
            }
            .name_box {
              &.flex {
                display: flex;
                align-items: center;
                gap: 0.5rem;
              }
              >span {
                font-weight: bold;
                width: 30px;
                text-align: end;
              }
              .input_wrap {
                width: 8rem;
              }
            }  
          }
          padding: 0;
          width: calc(100% - 300px - 1rem);
          .input_wrap {
            width: 70%;
          }
          input {
            width: 100%;
            line-height: 50px;
          }
        }
        + .item_wrap {
            margin-top: 35px;
        }
    }
  }
  .item_wrap {
    display: flex;
    .button_box {
      display: flex;
      margin-top: 1rem;
      gap: 1rem;
      justify-content: center;
    }
    .item_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 300px;
      font-size: 14px;
      font-weight: bold;
      padding: 20px 15px;
      background-color: $item_head_background_color;
      .comment {
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
      }
      
      &.plus_comment {
        height: 82px;
        + .item_cnt {
          input {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
