@use '../../../mixin' as mx;

.accordion {
  &-header {
    cursor: pointer;
    @include mx.flex_box;
    @include mx.flex_align_center;
    gap: 0 10px;
    @include mx.flex_no_wrap;
    font-weight: bold;
    line-height: 3;

    &-toggle {
      $size: 1.25em;
      min-width: $size;
      min-height: $size;
      width: $size;
      height: $size;
      @include mx.flex_box;
      @include mx.flex_align_center;
      @include mx.flex_content_center;

      &.default_toggle {
        background-color: currentColor;
        border-radius: 50%;
        &::before {
          display: block;
          color: #fff;
        }
        &.open {
          &::before {
            content: "－";
          }
        }
        &.close {
          &::before {
            content: "＋";
          }
        }
      }
    }
    // accordion-header
  }
  &-contents {
    &-wrap {
      transition: 0.5s;
      box-shadow: 0 0 0 1px inset #E2E2E3;
      // border: 1px solid #E2E2E3;
      // overflow: hidden;
      overflow: auto;
      @include mx.scroll_display_none;
    }
    // accordion-contents
  }
}