@import "../../../setting.scss";

.model_container {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1.5rem;
  width: calc(100% - 200px);
  height: 100%;
  .header_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    &.center {
      justify-content: center;
    }
    .title_wrap {
      display: flex;
      align-items: flex-end;
      gap: 1rem;
      .title {
        font-size: 24px;
      }
    }
  }
  .model_body_container {
    display: flex;
    height: 100%;
    width: 70%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .button_area {
      display: flex;
      justify-content: center;
      margin-bottom: 3rem;
      margin-top: 3rem;
      gap: 1rem;
    }
    .model_body_main_cnt {
      width: 100%;
    }
    .file_name_container {
      margin-top: 0.25rem;
    }
    .model_update_date {
      margin-top: 4rem;
      font-weight: bold;
      text-align: center;
    }
  }
}
