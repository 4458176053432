@use '../../../mixin' as mx;
@use '../../../setting' as st;

.search_box_wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
  .search_box {
    width: 90%;
    .accordion {
        @include mx.max_width_max;
        &-header {
          @include mx.max_width_max;
        }
    }
    &    .search_condition_box {
      font-size: 14px;
      @include mx.flex_box;
      @include mx.flex_direction_column;
      gap: 1em 0;
      padding: 1.5em;
      &__row {
        @include mx.flex_box;
        @include mx.flex_container_wrap;
        gap: 1em 0.5em;
      }
      .labeled_form {
        height: calc(3em - 2px);
        &__label {
          display: inline-block;
          width: 6em;
          text-align: right;
        }
        > div {
          height: 100%;
        }
        &.free_word {
          input {
            width: clamp(207px, 18.5vw, 20em);
            height: 100%;
          }
        }
      }
    }
  }
}