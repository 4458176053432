@charset "utf-8";

@mixin fontFamily() {
  font-family: "メイリオ", "Meiryo", "Hiragino Sans", "ヒラギノ角ゴ Pro W4",
    "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", sans-serif !important;
}

@mixin radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
}
@mixin radiusDetails($radius1,$radius2,$radius3,$radius4) {
  -webkit-border-radius: $radius1 $radius2 $radius3 $radius4;
  border-radius: $radius1 $radius2 $radius3 $radius4;
}

@mixin border-default($size:1px,$color:$border_default_color) {
  border: $size solid $color;
}

@mixin border-default-important($size:1px,$color:$border_default_color) {
  border: $size solid $color !important;
}

@mixin box_shadow($x: 0px, $y: 0px, $blur: 6px, $spread: 0px, $color: rgba(0, 0, 0, 0.1)) {
  box-shadow: $x $y $blur $spread $color;
}

@mixin loading() {
  -webkit-animation: 1.5s linear infinite rotation;
  animation: 1.5s linear infinite rotation;
}
@keyframes rotation{
  0%{ transform:rotate(0);}
  100%{ transform:rotate(360deg); }
}

@mixin triangle(
  $top_size, $right_size, $bottom_size, $left_size,
  $top_color:transparent, $right_color:transparent, $bottom_color:transparent, $left_color:transparent) {
  display: inline-block;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: $top_size $right_size $bottom_size $left_size;
  border-color: $top_color $right_color $bottom_color $left_color;
  }

@mixin animationSlow() {
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

@mixin hoverOpa() {
  opacity: 0.6;
  @include animationSlow();
}

@mixin SPHoverOpa() {
  opacity: 1 !important;
}

//テキスト選択無効
@mixin selectDisabled() {
  -ms-user-select: none; /* IE 10+ */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

//背景の上に表示するabsoluteの要素用
@mixin ElementOnBG() {
  z-index: 1000;
}

//汎用input[type="text"]
@mixin inputText() {
  color: #4b4b4b;
  border: 0;
  border-radius: 5px;
  box-shadow: none;
  font-size: 12px;
  height: 25px;
  text-align: left;
  padding: 5px;
}

@mixin flex_box() {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@mixin flex_align_start() {
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
@mixin flex_align_stretch() {
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
@mixin flex_align_center() {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@mixin flex_align_end() {
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}
@mixin flex_space_between() {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify;
}
@mixin flex_content_center() {
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}
@mixin flex_content_end() {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: end;
}
@mixin flex_content_around() {
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: distribute;
}
@mixin flex_container_wrap() {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}
@mixin flex_no_wrap() {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@mixin flex_direction_column() {
  -webkit-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
}

@mixin flex_all_center {
  @include flex_align_center;
  @include flex_content_center;
}

//3点リーダー
@mixin longTextEllipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//ドラッグ禁止
@mixin dontDrag() {
  -webkit-user-drag: none;
}

// btn
@mixin btnStyle1($color: #999999) {
  border: 1px solid $color;
  color: #ffffff;
  background-color: $color;
  min-width: 100px;
  min-height: 40px;
  font-size: 18px;

  &:hover {
    color: $color;
    background-color: #ffffff;
  }
}

@mixin btnStyle2(
  $bgColor: #999999,
  $shdColor: #555555,
  $radius: 4px,
  $shadowRange: 3px
) {
  border: 1px solid $bgColor;
  border-radius: $radius;
  color: #ffffff;
  background-color: $bgColor;
  min-width: 100px;
  min-height: 40px;
  font-size: 18px;
  box-shadow: $shadowRange $shadowRange $shdColor;
  cursor: pointer;
  outline: none;

  &:hover {
    opacity: 0.6;
  }
}

$breakpoints-min: (
  "sm": "screen and (min-width: 479px)",
  "md": "screen and (min-width: 768px)",
  "lg": "screen and (min-width: 991px)",
  "xl": "screen and (min-width: 1199px)",
) !default;

$breakpoints-max: (
  "xs": "screen and (max-width: 320px)",
  "sm": "screen and (max-width: 479px)",
  "md": "screen and (max-width: 768px)",
  "lg": "screen and (max-width: 991px)",
  "xl": "screen and (max-width: 1199px)",
) !default;

@mixin mq_min($breakpoint: md) {
  @media #{map-get($breakpoints-min, $breakpoint)} {
    @content;
  }
}

@mixin mq_max($breakpoint: md) {
  @media #{map-get($breakpoints-max, $breakpoint)} {
    @content;
  }
}

/* scroll 非表示 */
@mixin scroll_display_none {
  -ms-overflow-style: none; /* scroll 非表示 IE, Edge 対応 */
  scrollbar-width: none; /* scroll 非表示 Firefox 対応 */
  &::-webkit-scrollbar {
    display: none; /* scroll 非表示 chrome, safari */
  }
}

@mixin width_max {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
@mixin max_width_max {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}