@use '../../../mixin' as mx;
@use '../../../setting' as st;

.radio_button {
  $box_size: 1.5em;
  font-size: 100%;
  font-family: inherit;
  width: max-content;
  cursor: pointer;

  > label {
    @include mx.flex_box;
    @include mx.flex_align_center;
    gap: 0.5em;
    cursor: inherit;

    @include mx.flex_box;
    input {
      &:checked {
        + .radio_button__check {
          &::before {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }
  }

  &__check {
    width: $box_size;
    height: $box_size;
    border: 1px solid st.$form_checkbox_border_color;
    border-radius: 50%;
    background: #fff;
    @include mx.flex_box;
    @include mx.flex_all_center;

    &::before {
      display: block;
      content: "";
      width: calc(#{$box_size} - #{$box_size} * 0.41666666 );
      height: calc(#{$box_size} - #{$box_size} * 0.41666666 );
      background-color: st.$form_text_color;
      border-radius: inherit;
      position: absolute;
      transition: all 0.2s;
      opacity: 0;
      transform: scale(0);
    }
  }
}
